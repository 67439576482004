import {
  trackPageViewEvent,
  trackFrontpageViewEvent,
  trackArticleViewEvent,
  trackContentViewEvent,
  trackEngagementEvent,
  addPageLeaveTracking,
  trackActivePageLeave,
  setUser as setUserToPulseTracker,
  clearUser as removeUserFromPulseTracker,
  trackingState,
  getTracker
} from '@src/utils/helpers/tracking/pulse/tracker';
import { CATEGORY_ACTIVITY } from '@src/utils/helpers/category';
import eventProps, {
  loggedFoodOriginType
} from '@src/utils/helpers/tracking/eventproperties';
import { getAllActiveExperimentsTrackingData } from '@src/utils/helpers/experiments';

const eventCanBypassTrackingBlocking = true;

const setUser = trackingUser => {
  setUserToPulseTracker(trackingUser);
};

const clearUser = () => {
  removeUserFromPulseTracker();
};

const configureTrackingState = (
  productImprovementConsentStatus,
  isLoggedIn,
  cookieConsents
) => {
  trackingState.configure(
    productImprovementConsentStatus,
    isLoggedIn,
    cookieConsents
  );
};

async function trackLogbookPageView() {
  trackPageViewEvent({
    objectName: 'Logbook',
    isPaid: true
  });
}

async function trackRecipesPageView(category) {
  trackPageViewEvent({
    objectName: 'Recipes',
    isPaid: true,
    objectCategory: category
  });
}

async function trackDaySummaryPageView(category) {
  trackPageViewEvent({
    objectName: 'Day summary',
    isPaid: true,
    objectCategory: category
  });
}

async function trackQuickCaloriesPageView(intent, category) {
  trackPageViewEvent({
    objectName: 'Quick calories',
    isPaid: true,
    objectCategory: category,
    userIntent: intent
  });
}

async function trackCreateFoodstuffPageView(intent, category, originName) {
  trackPageViewEvent({
    objectName: 'Create foodstuff',
    isPaid: true,
    objectCategory: category,
    userIntent: intent,
    originName
  });
}

async function trackFoodstuffPageView(intent, category, originName) {
  trackPageViewEvent({
    objectName: 'Foodstuff',
    isPaid: true,
    objectCategory: category,
    userIntent: intent,
    originName
  });
}

async function trackRecipePageView({
  intent,
  category,
  originName,
  recipeName,
  recipeId,
  originType
}) {
  trackPageViewEvent({
    objectName: 'Recipe',
    isPaid: true,
    objectCategory: category,
    userIntent: intent,
    originName,
    shipCustom: { recipe_name: recipeName, recipe_id: recipeId },
    originType
  });
}

async function trackCreateRecipePageView(intent, originName) {
  trackPageViewEvent({
    objectName: 'Create Recipe',
    isPaid: true,
    userIntent: intent,
    originName
  });
}

async function trackShareRecipeDisclaimerPageView(category) {
  trackPageViewEvent({
    objectName: 'Share recipe disclaimer',
    isPaid: true,
    objectCategory: category,
    originName: 'Recipe'
  });
}

async function trackRecipePrintButtonClicked(originName) {
  trackPageViewEvent({
    objectName: 'Print recipe',
    isPaid: true,
    originName
  });
}

async function trackEditGoalPacePageView() {
  trackPageViewEvent({
    objectName: 'Pace',
    isPaid: true
  });
}

async function trackEditFastDaysPageView() {
  trackPageViewEvent({
    objectName: 'Fasting days',
    isPaid: true
  });
}

async function trackStartProgramPageView(originName) {
  trackPageViewEvent({
    originName,
    originType: eventProps.objectType.button,
    objectName: 'Start program',
    isPaid: true
  });
}

async function trackProgramPageView() {
  trackPageViewEvent({
    objectName: 'Program overview',
    isPaid: true
  });
}

async function trackArticlesListPageView(section) {
  trackPageViewEvent({
    objectName: 'Articles feed',
    isPaid: true,
    objectCategory: section
  });
}

async function trackArticlesSectionPageView(section) {
  trackPageViewEvent({
    objectName: 'Articles Section feed',
    isPaid: true,
    objectCategory: section
  });
}

async function trackReportRecipePageView() {
  trackPageViewEvent({
    originName: 'Report recipe @ Recipe view',
    originType: eventProps.objectType.button,
    objectName: 'Report recipe',
    isPaid: true
  });
}

async function trackArticlePageView({
  articleId,
  articleTitle,
  articleCategories,
  articleTags,
  isPaid,
  bypassTrackingBlocking = false,
  originUrl,
  hasSummary
}) {
  const flattenedCategory = articleCategories.join('>');
  const flattenedTags = articleTags.map(({ title }) => title);
  trackArticleViewEvent(
    {
      articleId,
      articleTitle,
      articleCategory: flattenedCategory,
      articleTags: flattenedTags,
      isPaid,
      originUrl,
      extras: { has_summary: hasSummary }
    },
    bypassTrackingBlocking
  );
}

async function trackMoreArticlesClicked(section) {
  trackPageViewEvent({
    objectName: 'Articles feed',
    objectCategory: section,
    isPaid: true
  });
}

async function trackFoodLogged(
  category,
  foodName,
  intent,
  originName,
  originType = loggedFoodOriginType?.button
) {
  trackEngagementEvent({
    type: 'Logged',
    intent,
    objectType: 'Food',
    objectName: foodName,
    objectAccessLevel: 'Paid',
    objectCategory: category,
    originName,
    originType
  });
}

async function trackDeletedLoggedItem(trackingData) {
  trackEngagementEvent({
    type: eventProps.type.completed,
    objectName: eventProps.objectName.deleteLoggedItem,
    objectType: eventProps.objectType.action,
    objectAccessLevel: eventProps.accessLevel.paid,
    ...trackingData
  });
}

async function trackActivityLogged(activityName) {
  trackEngagementEvent({
    type: 'Logged',
    objectType: 'Activity',
    objectName: activityName,
    objectAccessLevel: 'Paid',
    objectCategory: CATEGORY_ACTIVITY
  });
}

async function trackWeightLogged(weight, originType, originName) {
  trackEngagementEvent({
    type: 'Logged',
    objectType: 'Weight',
    objectName: weight,
    objectAccessLevel: 'Paid',
    originType,
    originName
  });
}

async function trackLogWeightView(originName) {
  trackPageViewEvent({
    originName,
    originType: eventProps.objectType.button,
    objectName: 'Log weight',
    isPaid: true
  });
}

async function trackWaistLogged(weight, originType, originName) {
  trackEngagementEvent({
    type: 'Logged',
    objectType: 'Waist',
    objectName: weight,
    objectAccessLevel: 'Paid',
    originType,
    originName
  });
}

async function trackRecipeShared(objectCategory) {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    objectName: 'Share recipe',
    objectAccessLevel: 'Paid',
    objectCategory
  });
}

async function trackRecipeCreated(name) {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Save new recipe',
    objectName: name,
    objectAccessLevel: 'Paid'
  });
}

async function trackRecipeCopied(name) {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Save new recipe copy',
    objectName: name,
    objectAccessLevel: 'Paid'
  });
}

async function trackRecipeUpdated(name) {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Update recipe',
    objectName: name,
    objectAccessLevel: 'Paid'
  });
}

async function trackRecipeDeleted() {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Delete recipe',
    objectAccessLevel: 'Paid'
  });
}

async function trackFoodstuffCreated(name) {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Save new foodstuff',
    objectName: name,
    objectAccessLevel: 'Paid'
  });
}

async function trackFoodstuffUpdated(name) {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Update foodstuff',
    objectName: name,
    objectAccessLevel: 'Paid'
  });
}

async function trackFoodstuffDeleted() {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Delete foodstuff',
    objectAccessLevel: 'Paid'
  });
}

async function trackRecipeIssueReported(objectCategory) {
  trackEngagementEvent({
    originName: 'Report recipe @ Recipe view',
    originType: eventProps.objectType.button,
    type: 'Completed',
    objectType: 'Action',
    intent: 'Recipe issue reported',
    objectAccessLevel: 'Paid',
    objectCategory
  });
}

async function trackLogWaistView(originName) {
  trackPageViewEvent({
    originName,
    originType: eventProps.objectType.button,
    objectName: 'Log waist',
    isPaid: true
  });
}

async function trackProgramStarted(isKeepWeight, originName) {
  trackEngagementEvent({
    type: 'Started',
    originName,
    originType: eventProps.objectType.button,
    objectType: 'Program',
    objectName: isKeepWeight
      ? eventProps.programType.keepWeight
      : eventProps.programType.loseWeight,
    objectAccessLevel: 'Paid'
  });
}

async function trackPartialGoalPaceEdited(pace) {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Change goal pace',
    objectName: `New pace: ${pace}`,
    objectAccessLevel: 'Paid'
  });
}

async function trackInitialPartialGoalPaceEdited() {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Change goal pace when starting new program',
    objectName: `Change initial goal pace`,
    objectAccessLevel: 'Paid'
  });
}

async function trackFastingDaysEdited(fastingDays) {
  trackEngagementEvent({
    type: 'Completed',
    objectType: 'Action',
    intent: 'Change fasting day',
    objectName: fastingDays,
    objectAccessLevel: 'Paid'
  });
}

async function trackWelcomeSalePageView() {
  trackFrontpageViewEvent();
}

async function trackSubscriptionPickerSalePageView(campaignName) {
  trackPageViewEvent(
    {
      objectName: 'Sale',
      isPaid: false,
      objectCategory: 'Become a member',
      originName: campaignName,
      ...{ experiments: getAllActiveExperimentsTrackingData() }
    },
    eventCanBypassTrackingBlocking
  );
}

async function trackBmiWidgetSalePageView() {
  trackPageViewEvent(
    {
      objectName: 'Sale',
      isPaid: false,
      objectCategory: 'BMI widget'
    },
    eventCanBypassTrackingBlocking
  );
}

async function trackNoSubscriptionSalePageView() {
  trackPageViewEvent(
    {
      objectName: 'Sale',
      isPaid: true,
      objectCategory: 'No subscription',
      ...{ experiments: getAllActiveExperimentsTrackingData() }
    },
    eventCanBypassTrackingBlocking
  );
}

async function trackOurServiceSalePageView() {
  trackPageViewEvent(
    {
      objectName: 'Sale',
      isPaid: true,
      objectCategory: 'How it works'
    },
    eventCanBypassTrackingBlocking
  );
}

async function track404SalePageView() {
  trackPageViewEvent({
    objectName: 'Sale',
    isPaid: true,
    objectCategory: '404'
  });
}

async function trackLandingPageMemberPageView() {
  trackPageViewEvent({
    objectName: 'Landing page member',
    isPaid: true
  });
}

async function trackHealthConsentPageView(
  objectName,
  objectCategory,
  originName
) {
  trackPageViewEvent({
    objectName,
    isPaid: true,
    objectCategory,
    originType: eventProps.objectType.button,
    originName
  });
}

async function trackWelcomeToPageView() {
  trackPageViewEvent(
    {
      objectName: 'Post purchase',
      isPaid: true,
      objectCategory: 'post purchase - get started'
    },
    eventCanBypassTrackingBlocking
  );
}

async function trackHealthConsentJourney(objectName, objectCategory, journey) {
  trackEngagementEvent({
    type: eventProps.type.completed,
    objectType: eventProps.objectType.action,
    intent: journey,
    objectName,
    objectCategory,
    objectAccessLevel: 'Paid'
  });
}

async function trackFullSalesWidgetClicked(url, originUrl) {
  const urlParams = new URLSearchParams(url);

  let offerLength = 0;
  const code = urlParams.get('code');
  if (code) {
    if (code.includes('3M')) {
      offerLength = 3;
    } else if (code.includes('6M')) {
      offerLength = 6;
    } else if (code.includes('12M')) {
      offerLength = 12;
    } else if (code.includes('1M')) {
      offerLength = 1;
    }
  }
  const objectCategory = `Price table ${offerLength}m`;
  trackSalesWidgetClicked({
    objectElementType: eventProps.objectType.button,
    objectType: eventProps.objectType.uiElement,
    intent: 'Purchase',
    objectCategory,
    originUrl,
    ...{ experiments: getAllActiveExperimentsTrackingData() }
  });
}

async function trackShortSalesWidgetClicked(originUrl, objectCategory) {
  trackSalesWidgetClicked({
    objectCategory: objectCategory || 'Small banner',
    originUrl,
    objectType: eventProps.objectType.button,
    ...{ experiments: getAllActiveExperimentsTrackingData() }
  });
}

async function trackBmiClicked(originUrl, categorySuffix) {
  trackSalesWidgetClicked({
    objectCategory: `Bmi widget - ${categorySuffix}`,
    originUrl,
    objectType: eventProps.objectType.button
  });
}

async function trackFooterAdSalesWidgetClicked(originUrl, objectCategory) {
  trackSalesWidgetClicked({
    objectCategory: objectCategory || 'Footer Ad',
    originUrl,
    objectType: eventProps.objectType.button
  });
}

async function trackHeroBannerSalesWidgetClicked(originUrl) {
  trackSalesWidgetClicked({
    objectCategory: 'Hero banner',
    originUrl,
    objectType: eventProps.objectType.button
  });
}

async function trackTextLinkSalesWidgetClicked(originUrl) {
  trackSalesWidgetClicked({
    objectCategory: 'Text link',
    originUrl,
    objectType: eventProps.objectType.link
  });
}

async function trackSalesWidgetClicked({
  objectCategory,
  originUrl,
  objectType,
  intent,
  objectElementType,
  experiments
}) {
  trackEngagementEvent(
    {
      type: eventProps.type.clicked,
      objectType,
      objectName: 'Sales widget',
      objectAccessLevel: 'Free',
      originType: 'Page',
      objectCategory,
      objectElementType,
      originUrl,
      intent,
      experiments
    },
    eventCanBypassTrackingBlocking
  );
}

async function trackPurchaseLink(url) {
  const urlParams = new URLSearchParams(url);
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.button,
    objectName: 'Purchase link clicked',
    objectAccessLevel: 'Free',
    intent: url,
    originId: urlParams.get('content_id'),
    originType: urlParams.get('type'),
    originChannel: urlParams.get('utm_channel'),
    originSource: urlParams.get('utm_source'),
    originCampaign: urlParams.get('utm_campaign'),
    originTerm: urlParams.get('utm_term'),
    originContent: urlParams.get('utm_content'),
    originUrl: urlParams.get('utm_url'),
    ...{ experiments: getAllActiveExperimentsTrackingData() }
  });
}

async function trackDate(serverDateText) {
  const dateFromText = new Date(serverDateText);
  if (dateFromText && dateFromText.toString() !== 'Invalid Date') {
    const epocFromServer = dateFromText.getTime() / 1000;
    const epocNow = Date.now() / 1000;
    const secondsDiff = Math.round(epocFromServer - epocNow);
    trackEngagementEvent({
      intent: epocNow,
      type: 'Debugging',
      objectType: 'Date',
      objectCategory: epocFromServer,
      objectName: `${secondsDiff}`
    });
  }
}

async function trackGoalsAchieved({ objectName, objectCategory }) {
  trackEngagementEvent({
    type: eventProps.type.achieved,
    description: 'Achieved Goal',
    objectType: eventProps.objectType.goal,
    objectName,
    objectCategory,
    objectAccessLevel: eventProps.accessLevel.paid
  });
}

async function trackHealthConsentForProductImprovementPageView() {
  trackPageViewEvent(
    {
      objectName: 'Product development health consent',
      isPaid: true,
      objectCategory: 'existing user'
    },
    eventCanBypassTrackingBlocking
  );
}

async function trackHealthConsentForProductImprovementAccepted() {
  trackEngagementEvent(
    {
      type: 'Completed',
      objectType: 'Action',
      objectName: 'Product development health consent given',
      objectAccessLevel: 'Paid',
      objectCategory: 'existing user',
      originName: 'Health data tracking consent accept button',
      originType: 'Button'
    },
    eventCanBypassTrackingBlocking
  );
}

async function trackHealthConsentForProductImprovementDeclined() {
  trackEngagementEvent(
    {
      type: 'Completed',
      objectType: 'Action',
      objectName: 'Product development health consent declined',
      objectAccessLevel: 'Paid',
      objectCategory: 'existing user',
      originName: 'Health data tracking consent decline button',
      originType: 'Button'
    },
    eventCanBypassTrackingBlocking
  );
}

async function trackWeeklyMenuPageView(trackingParams) {
  trackPageViewEvent({
    type: eventProps.type.view,
    objectName: eventProps.objectName.weeklyMenu,
    isPaid: true,
    ...trackingParams
  });
}

async function trackWeeklyMenuIntroductionPageView(trackingParams = {}) {
  trackPageViewEvent({
    type: eventProps.type.view,
    objectName: eventProps.objectName.weeklyMenuIntroduction,
    objectCategory: eventProps.objectCategory.onboarding,
    isPaid: true,
    ...trackingParams
  });
}

async function trackWeeklyMenuSettingsPageView(trackingParams = {}) {
  trackPageViewEvent({
    type: eventProps.type.view,
    objectName: eventProps.objectName.weeklyMenuSettings,
    isPaid: true,
    ...trackingParams
  });
}

async function trackWeeklyMenuFakeCalculations(trackingParams = {}) {
  trackPageViewEvent({
    type: eventProps.type.view,
    objectName: eventProps.objectName.weeklyMenuFakeCalculations,
    isPaid: true,
    ...trackingParams
  });
}

async function trackWeeklyMenuOnboardingSummary(trackingParams = {}) {
  trackPageViewEvent({
    type: eventProps.type.view,
    objectName: eventProps.objectName.weeklyMenuOnboardingSummary,
    isPaid: true,
    ...trackingParams
  });
}

async function trackWeeklyMenuPreferencesButtonClicked(trackingParams = {}) {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.button,
    objectName: eventProps.objectName.menuSettings,
    objectAccessLevel: eventProps.accessLevel.paid,
    originType: eventProps.objectType.page,
    ...trackingParams
  });
}

async function trackWeeklyMenuPreferencesSetClicked(trackingParams = {}) {
  trackEngagementEvent({
    type: eventProps.type.completed,
    objectType: eventProps.objectType.action,
    objectAccessLevel: eventProps.accessLevel.paid,
    originType: eventProps.objectType.page,
    ...trackingParams
  });
}

async function trackWeeklyMenuCancelCustomizingClicked(trackingParams = {}) {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectName: eventProps.objectName.cancelCustomizingMenu,
    objectType: eventProps.objectType.button,
    objectAccessLevel: eventProps.accessLevel.paid,
    originName: eventProps.originName.menuOnboarding,
    originType: eventProps.objectType.page,
    ...trackingParams
  });
}

async function trackWeeklyMenuCustomizeLaterClicked(trackingParams = {}) {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectName: eventProps.objectName.customizeMenuLater,
    objectType: eventProps.objectType.button,
    objectAccessLevel: eventProps.accessLevel.paid,
    originName: eventProps.originName.menuOnboarding,
    originType: eventProps.objectType.page,
    ...trackingParams
  });
}

async function trackWeeklyMenuCustomizeClicked(trackingParams = {}) {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectName: eventProps.objectName.customizeMenu,
    objectType: eventProps.objectType.button,
    objectAccessLevel: eventProps.accessLevel.paid,
    originType: eventProps.objectType.page,
    ...trackingParams
  });
}

async function trackChangeMenuView(trackingParams) {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.button,
    objectName: eventProps.objectName.changeMenuView,
    objectAccessLevel: eventProps.accessLevel.paid,
    originName: eventProps.originName.weeklyMenu,
    originType: eventProps.objectType.page,
    ...trackingParams
  });
}

async function trackMenuSwitchMealClicked(category) {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.button,
    objectName: eventProps.objectName.menuSwitchMeal,
    objectAccessLevel: eventProps.accessLevel.paid,
    objectCategory: category
  });
}

async function trackMenuMealSwitched(category) {
  trackEngagementEvent({
    type: eventProps.type.completed,
    objectType: eventProps.objectType.action,
    objectName: eventProps.objectName.menuMealSwitched,
    objectAccessLevel: eventProps.accessLevel.paid,
    objectCategory: category
  });
}

async function trackSimilarRecipeItemClicked() {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.item,
    objectName: eventProps.objectName.similarRecipe,
    objectAccessLevel: eventProps.accessLevel.paid,
    originName: eventProps.originName.recipe,
    originType: eventProps.objectType.page
  });
}

async function trackSimilarRecipesContentView() {
  trackContentViewEvent({
    objectName: 'Similar recipes',
    isPaid: true
  });
}

async function trackSalesBoxesExperimentContentView(originUrl, objectCategory) {
  trackContentViewEvent({
    objectName: 'Sales widget - price table',
    objectCategory,
    originUrl,
    isPaid: true,
    ...{ experiments: getAllActiveExperimentsTrackingData() }
  });
}

async function trackShortSalesWidgetContentView(objectCategory) {
  trackContentViewEvent({
    objectName: 'Sales widget - small banner',
    objectCategory: objectCategory || 'Article',
    isPaid: false,
    ...{ experiments: getAllActiveExperimentsTrackingData() }
  });
}

async function trackSingleQuestionContentView(objectCategory) {
  trackContentViewEvent({
    objectName: 'Single question',
    objectCategory
  });
}

async function trackPollContentView(objectCategory) {
  trackContentViewEvent({
    objectName: 'Poll',
    objectCategory
  });
}

async function addArticlePageLeaveTracking(ref, bypassTrackingBlocking) {
  addPageLeaveTracking(
    {
      articleElement: ref,
      objectName: 'Article'
    },
    bypassTrackingBlocking
  );
}

async function addSalePageLeaveTracking(ref) {
  addPageLeaveTracking({ articleElement: ref, objectName: 'Sale' });
}

async function trackArticleActivePageLeave(eventName, bypassTrackingBlocking) {
  trackActivePageLeave(
    {
      objectName: 'Article',
      eventName
    },
    bypassTrackingBlocking
  );
}
async function trackSaleActivePageLeave(eventName) {
  trackActivePageLeave({
    objectName: 'Sale',
    eventName
  });
}

async function trackPopularTagsOnFeaturedRecipesClicked(objectCategory) {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.button,
    objectName: eventProps.objectName.popularTagsOnFeaturedRecipes,
    objectCategory,
    objectAccessLevel: eventProps.accessLevel.paid
  });
}

async function trackArticleClicked(trackingData) {
  const defaults = {
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.uiElement,
    objectElementType: eventProps.objectElementType.teaser,
    objectAccessLevel: eventProps.accessLevel.free
  };
  trackEngagementEvent({
    ...defaults,
    ...trackingData
  });
}

async function trackBlueLinkClicked(trackingData) {
  const defaults = {
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.uiElement,
    objectElementType: eventProps.objectElementType.blueLink,
    objectCategory: eventProps.objectCategory.blueLink,
    objectAccessLevel: eventProps.accessLevel.free
  };
  trackEngagementEvent({
    ...defaults,
    ...trackingData
  });
}

async function trackSingleQuestionAnswerClick(objectName, objectCategory) {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.button,
    objectCategory: objectCategory
      ? eventProps.objectCategory.singleQuestion.rightAnswer
      : eventProps.objectCategory.singleQuestion.wrongAnswer,
    objectName
  });
}

async function trackPollAnswerClick(objectName, objectCategory) {
  trackEngagementEvent({
    type: eventProps.type.clicked,
    objectType: eventProps.objectType.button,
    objectCategory,
    objectName
  });
}

export default {
  trackLogbookPageView,
  trackRecipePrintButtonClicked,
  trackRecipesPageView,
  trackRecipePageView,
  trackCreateRecipePageView,
  trackShareRecipeDisclaimerPageView,
  trackFoodstuffPageView,
  trackCreateFoodstuffPageView,
  trackDaySummaryPageView,
  trackQuickCaloriesPageView,
  trackEditGoalPacePageView,
  trackEditFastDaysPageView,
  trackStartProgramPageView,
  trackProgramPageView,
  trackArticlesSectionPageView,
  trackArticlesListPageView,
  trackArticlePageView,
  trackReportRecipePageView,
  trackArticleClicked,
  trackMoreArticlesClicked,
  trackFoodLogged,
  trackActivityLogged,
  trackWeightLogged,
  trackWaistLogged,
  trackProgramStarted,
  trackPartialGoalPaceEdited,
  trackInitialPartialGoalPaceEdited,
  trackFastingDaysEdited,
  trackWelcomeSalePageView,
  trackSubscriptionPickerSalePageView,
  trackBmiWidgetSalePageView,
  trackOurServiceSalePageView,
  trackNoSubscriptionSalePageView,
  track404SalePageView,
  trackLandingPageMemberPageView,
  trackHealthConsentPageView,
  trackWelcomeToPageView,
  trackHealthConsentJourney,
  trackRecipeCreated,
  trackRecipeCopied,
  trackRecipeUpdated,
  trackRecipeDeleted,
  trackRecipeShared,
  trackFoodstuffCreated,
  trackFoodstuffUpdated,
  trackFoodstuffDeleted,
  trackRecipeIssueReported,
  setUser,
  clearUser,
  trackPurchaseLink,
  trackGoalsAchieved,
  trackLogWeightView,
  trackLogWaistView,
  trackFullSalesWidgetClicked,
  trackShortSalesWidgetClicked,
  trackBmiClicked,
  trackTextLinkSalesWidgetClicked,
  trackFooterAdSalesWidgetClicked,
  trackHeroBannerSalesWidgetClicked,
  trackDate,
  configureTrackingState,
  trackHealthConsentForProductImprovementAccepted,
  trackHealthConsentForProductImprovementDeclined,
  trackHealthConsentForProductImprovementPageView,
  trackWeeklyMenuPageView,
  trackWeeklyMenuIntroductionPageView,
  trackWeeklyMenuSettingsPageView,
  trackWeeklyMenuFakeCalculations,
  trackWeeklyMenuOnboardingSummary,
  trackWeeklyMenuPreferencesButtonClicked,
  trackWeeklyMenuPreferencesSetClicked,
  trackWeeklyMenuCancelCustomizingClicked,
  trackWeeklyMenuCustomizeClicked,
  trackWeeklyMenuCustomizeLaterClicked,
  trackChangeMenuView,
  trackDeletedLoggedItem,
  trackMenuSwitchMealClicked,
  trackMenuMealSwitched,
  trackSimilarRecipeItemClicked,
  trackSimilarRecipesContentView,
  trackSalesBoxesExperimentContentView,
  trackSingleQuestionContentView,
  trackPollContentView,
  trackArticleActivePageLeave,
  trackSaleActivePageLeave,
  addSalePageLeaveTracking,
  addArticlePageLeaveTracking,
  trackPopularTagsOnFeaturedRecipesClicked,
  trackShortSalesWidgetContentView,
  trackBlueLinkClicked,
  trackSingleQuestionAnswerClick,
  trackPollAnswerClick,
  tracker: getTracker
};
